import { AllergyCategoryDto } from '../Type/AllergyCategoryDto'

export class AllergyRefCategory {
    constructor(public id: number, public name: string, public groupId?: number, public isActive?: boolean) {}

    public formateItemToFormData(): AllergyCategoryDto {
        return {
            id: this.id,
            name: this.name,
            group_id: this.groupId,
            is_active: this.isActive,
        }
    }
}
