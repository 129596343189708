import AllergyRefAllergen from '../Classes/AllergyRefAllergen'
import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import { AllergyRefCategoryAPIAdapter } from './AllergyRefCategoryAPIAdapter'
import { AllergyRefCategory } from '../Classes/AllergyRefCategory'
import { AllergyLinkAllergenCategory } from '../Classes/AllergyLinkAllergenCategory'
import { AllergyLinkAllergenCategoryAPIAdapter } from './AllergyLinkAllergenCategoryAPIAdapter'
import { AllergyAllergenDto } from '../Type/AllergyAllergenDto'
import { get } from 'lodash'
import { OneOrMore, Undefinable } from '@internal-libraries/kheops-ui-lib'
import { AllergyLinkAllergenCategoryDto } from '../Type/AllergyLinkAllergenCategoryDto'

export class AllergyRefAllergenAPIAdapter implements ApiDataAdapter<AllergyRefAllergen> {
    createClass(apiData: AllergyAllergenDto): AllergyRefAllergen {
        const allergyLinkAllergenCategory: Undefinable<OneOrMore<AllergyLinkAllergenCategoryDto>> = get(
            apiData,
            'allergyLinkAllergenCategory',
        )
        const categories: AllergyRefCategory[] = []
        const linkAllergens: AllergyLinkAllergenCategory[] = []
        if (allergyLinkAllergenCategory) {
            let allergyLinkAllergenCategories: AllergyLinkAllergenCategoryDto[] = []
            allergyLinkAllergenCategories = Array.isArray(allergyLinkAllergenCategory)
                ? allergyLinkAllergenCategory
                : [allergyLinkAllergenCategory]

            if (allergyLinkAllergenCategories) {
                allergyLinkAllergenCategories.forEach(value => {
                    if (value.allergyRefCategory && value.code) {
                        categories.push(new AllergyRefCategoryAPIAdapter().createClass(value.allergyRefCategory))
                    }
                    if (value.is_active) {
                        value.refCategory = value.allergyRefCategory
                        linkAllergens.push(new AllergyLinkAllergenCategoryAPIAdapter().createClass(value))
                    }
                })
            }
        }

        return new AllergyRefAllergen(
            Number(get(apiData, 'id')),
            get(apiData, 'is_active'),
            get(apiData, 'is_validate'),
            get(apiData, 'group_id'),
            get(apiData, 'name'),
            categories,
            linkAllergens,
            get(apiData, 'ref_type_id'),
            get(apiData, 'tags'),
            get(apiData, 'isReferenced'),
        )
    }
}
