import { CustomSkeletonLoaderType } from '@internal-libraries/kheops-ui-lib'

export const ClvrSkeletonLoaderTypeConst: CustomSkeletonLoaderType = {
    'header-icon-title': 'avatar, heading',
    'header-with-button-content': 'header-icon-title, button',
    'header-icon-title-switch': 'avatar, heading@2',
    'large-heading': 'heading',
    'prsc-posology-line': 'button@5',
    'prsc-temporality-header': 'avatar, heading@3',
    'prsc-temporality': 'button@4',
    'prsc-modal-footer': 'heading, prsc-modal-footer-buttons',
    'prsc-modal-footer-buttons': 'button@2',
    'prsc-modal-add-product': 'prsc-modal-add-product-qty, prsc-modal-add-product-unit, heading',
    'prsc-modal-add-product-qty': 'button',
    'prsc-modal-add-product-unit': 'button',
    'prsc-modal-header-title': 'avatar, large-heading',
    'prsc-search-product-options': 'heading@3',
    'prsc-frequencies-management-schedules-header': 'button@3',
    'switch-list': 'input',
    input: 'heading',
}
