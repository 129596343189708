var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("kh-switch", {
        staticClass: "posology-customized-switch",
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c("div", { staticClass: "clvr-prescription-modal-text" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$t(
                          "prescriptionClevEHR:prescriptionClevEHR-period-title"
                        )
                      ) +
                      "\n            "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.periodic,
          callback: function ($$v) {
            _vm.periodic = $$v
          },
          expression: "periodic",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "clvr-posology-customized-modal-content d-flex flex-row",
        },
        [
          _vm.periodic
            ? _c(
                "div",
                {
                  staticClass:
                    "clvr-prescription-modal-text clvr-prescription-modal-center-text",
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.customizedModalTextPeriodBuilt) +
                      "\n        "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "kh-quantity-picker",
            _vm._b(
              {
                attrs: { value: _vm.quantity },
                on: { input: _vm.onQuantityChange },
              },
              "kh-quantity-picker",
              _vm.quantityPickerProps,
              false
            )
          ),
          _vm._v(" "),
          !_vm.periodic
            ? _c(
                "div",
                {
                  staticClass:
                    "clvr-prescription-modal-text clvr-prescription-modal-center-text",
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.translatedTextBetween) +
                      "\n        "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("clvr-prsc-unit-selector", {
            attrs: {
              "max-width": _vm.unitSelectorMaxWidth,
              options: _vm.computedUnitOptions,
              unattached: "",
            },
            model: {
              value: _vm.unitObject,
              callback: function ($$v) {
                _vm.unitObject = $$v
              },
              expression: "unitObject",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.errorMessages.length
        ? _c(
            "div",
            [
              _c("kh-input-message", {
                model: {
                  value: _vm.errorMessages,
                  callback: function ($$v) {
                    _vm.errorMessages = $$v
                  },
                  expression: "errorMessages",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }