import { get } from 'lodash'
import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import AllergyRefReaction from '../Classes/AllergyRefReaction'
import { AllergyReactionDto } from '../Type/AllergyReactionDto'
import Vue from 'vue'

export class AllergyRefReactionAPIAdapter implements ApiDataAdapter<AllergyRefReaction> {
    public createClass(apiData: AllergyReactionDto): AllergyRefReaction {
        const groupId = get(apiData, 'group_id')
        const name = (groupId ? '' : 'dPpatients:') + get(apiData, 'name')
        return new AllergyRefReaction(
            get(apiData, 'id'),
            groupId,
            Vue.$i18n.translate(name),
            get(apiData, 'stage'),
            get(apiData, 'is_active'),
            get(apiData, 'isReferenced'),
        )
    }
}
