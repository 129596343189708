import { SysFieldValue } from '../../../system/vue/common/SysFieldValue'
import { DietRefFoodDto } from '../Type/DietRefFoodDto'
import { Nullable } from '@internal-libraries/kheops-ui-lib'

export class DietRefFood {
    public groupId?: number
    public tags?: string
    public isPrescriptible?: boolean
    public isUsual?: boolean
    public isValid?: boolean
    public isActive?: boolean
    public refCodes?: SysFieldValue[]
    public isReferenced?: boolean

    constructor(public id: Nullable<number>, public type: string, public name: string) {}

    public formateItemToFormData(): DietRefFoodDto {
        let codesDto: string[] = []
        if (this.refCodes) {
            codesDto = this.refCodes.map(code => code.value)
        }

        return {
            type: this.type,
            name: this.name,
            id: this.id,
            group_id: this.groupId,
            tags: this.tags,
            is_prescriptible: this.isPrescriptible,
            is_usual: this.isUsual,
            is_valid: this.isValid,
            is_active: this.isActive,
            refCodes: codesDto,
        }
    }
}
