import Vue from 'vue'
import { dietEnum } from '../Enum/dietEnum'

export default {
    emptyAllergyStoreByKey: (state, storeKey) => {
        Vue.set(state.allergyDataByKey, storeKey, {
            reactions: [],
            allergies: [],
            categories: [],
        })
    },
    emptyDietsStoreByKey: (state, storeKey) => {
        Vue.set(state.dietDataByKey, storeKey, {
            dietPrescriptions: [],
            dietsRefFood: [],
            dietUsuals: [],
        })
    },
    saveReactions: (state, args) => {
        state.allergyDataByKey[args.storeKey].reactions = args.reactions
    },
    saveAllergies: (state, args) => {
        state.allergyDataByKey[args.storeKey].allergies = args.allergies
    },
    addAllergy: (state, args) => {
        state.allergyDataByKey[args.storeKey].allergies.push(args.allergy)
    },
    saveCategories: (state, args) => {
        state.allergyDataByKey[args.storeKey].categories = args.categories
    },
    deleteAllergy: (state, args) => {
        const allergyIndex = state.allergyDataByKey[args.storeKey].allergies.findIndex((item) => item.id === args.allergyId)
        state.allergyDataByKey[args.storeKey].allergies.splice(allergyIndex, 1)
    },
    deleteReaction: (state, args) => {
        const allergyIndex = state.allergyDataByKey[args.storeKey].reactions.findIndex((item) => item.id === args.reactionId)
        state.allergyDataByKey[args.storeKey].reactions.splice(allergyIndex, 1)
    },
    saveAllergy: (state, args) => {
        const allergyIndex = state.allergyDataByKey[args.storeKey].allergies.findIndex((item) => item.id === args.allergy.id)
        state.allergyDataByKey[args.storeKey].allergies.splice(allergyIndex, 1, args.allergy)
    },
    addReaction: (state, args) => {
        state.allergyDataByKey[args.storeKey].reactions.push(args.reaction)
    },
    saveReaction: (state, args) => {
        const allergyIndex = state.allergyDataByKey[args.storeKey].reactions.findIndex((item) => item.id === args.reaction.id)
        state.allergyDataByKey[args.storeKey].reactions.splice(allergyIndex, 1, args.reaction)
    },
    saveDietPrescriptions: (state, args) => {
        state.dietDataByKey[args.storeKey].dietPrescriptions = args.dietPrescriptions
        state.dietDataByKey[args.storeKey].dietUsuals = args.dietUsuals
    },
    saveDietRefFood: (state, args) => {
        state.dietDataByKey[args.storeKey].dietsRefFood = args.dietRefFoods
    },
    addDietRefFood: (state, args) => {
        const dietIndex = state.dietDataByKey[args.storeKey].dietsRefFood.findIndex((item) => item.id === args.dietRefFood.id)
        state.dietDataByKey[args.storeKey].dietsRefFood.splice(dietIndex, 1, args.dietRefFood)
    },
    createDietRefFood: (state, args) => {
        state.dietDataByKey[args.storeKey].dietsRefFood.push(args.dietRefFood)
    },
    addDietPrescription: (state, args) => {
        args.dietPrescription.setDataForView()
        const keyTable = args.dietPrescription.isPrescription ? dietEnum.dietPrescriptions : dietEnum.dietUsuals
        state.dietDataByKey[args.storeKey][keyTable].push(args.dietPrescription)
    },
    updateDietPrescription: (state, args) => {
        const keyTable = args.dietPrescription.isPrescription ? dietEnum.dietPrescriptions : dietEnum.dietUsuals
        const dietIndex = state.dietDataByKey[args.storeKey][keyTable].findIndex((item) => item.id === args.dietPrescription.id)
        state.dietDataByKey[args.storeKey][keyTable].splice(dietIndex, 1, args.dietPrescription)
    },
    deleteDietPrescription: (state, args) => {
        const keyTable = args.dietPrescription.isPrescription ? dietEnum.dietPrescriptions : dietEnum.dietUsuals
        const dietIndex = state.dietDataByKey[args.storeKey][keyTable].findIndex((item) => item.id === args.dietPrescription.id)
        state.dietDataByKey[args.storeKey][keyTable].splice(dietIndex, 1)
    },
    deleteDietRefFood: (state, args) => {
        const dietIndex = state.dietDataByKey[args.storeKey].dietsRefFood.findIndex((item) => item.id === args.dietRefFood.id)
        state.dietDataByKey[args.storeKey].dietsRefFood.splice(dietIndex, 1)
    },
}
