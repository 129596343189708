import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import { get } from 'lodash'
import { AllergyLinkSejourDto } from '../Type/AllergyLinkSejourDto'
import { AllergyLinkSejour } from '../Classes/AllergyLinkSejour'
import { AllergyStayAPIAdapter } from '../Adapter/AllergyStayAPIAdapter'

export class AllergyLinkSejourAPIAdapter implements ApiDataAdapter<AllergyLinkSejour> {
    createClass(apiData: AllergyLinkSejourDto): AllergyLinkSejour {
        const refLinkedStays = get(apiData, '_refLinkedStays')
        return new AllergyLinkSejour(
            Number(get(apiData, 'id')),
            get(apiData, 'allergy_id'),
            get(apiData, 'sejour_id'),
            get(apiData, 'is_active'),
            refLinkedStays ? new AllergyStayAPIAdapter().createClass(refLinkedStays) : undefined,
        )
    }
}
