import { AllergyLinkAllergenCategory } from '../Classes/AllergyLinkAllergenCategory'
import { AllergyLinkAllergenCategoryDto } from '../Type/AllergyLinkAllergenCategoryDto'
import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import { get } from 'lodash'
import { AllergyRefCategoryAPIAdapter } from '../Adapter/AllergyRefCategoryAPIAdapter'
import { AllergyCategoryDto } from '../Type/AllergyCategoryDto'

export class AllergyLinkAllergenCategoryAPIAdapter implements ApiDataAdapter<AllergyLinkAllergenCategory> {
    createClass(apiData: AllergyLinkAllergenCategoryDto): AllergyLinkAllergenCategory {
        const refCategory = get(apiData, 'refCategory')
        return new AllergyLinkAllergenCategory(
            Number(get(apiData, 'id')),
            get(apiData, 'ref_category_id'),
            get(apiData, 'code'),
            get(apiData, 'ref_allergen_id'),
            get(apiData, 'is_active'),
            new AllergyRefCategoryAPIAdapter().createClass(refCategory as AllergyCategoryDto),
        )
    }
}
