import { DietRefFood } from '../../../dPpatients/vue/Classes/DietRefFood'
import { User } from '../../../admin/vue/classes/User'
import { DietDto } from '../Type/DietDto'
import ChainingPatientServiceHistory from '../../../chaining/vue/classes/ChainingPatientServiceHistory'
import Vue from 'vue'
import { AllergyFoodPatientHistoryApiAdapter } from '../../../soins/vue/adapter/AllergyFoodPatientHistoryApiAdapter'
import { DateTimeRange, KhDate } from '@internal-libraries/kheops-ui-lib'

export default class Diet {
    public groupId?: number
    public dateStart?: Date
    public dateEnd?: Date
    public isImportant?: boolean
    public isSigned?: boolean
    public signedUserId?: number
    public isActive?: boolean
    public dietRefFoodType?: string
    public dietName?: string
    public fastInProgress?: boolean
    public refFood?: DietRefFood
    public refSignedUser?: User
    public signedUserInitial?: string
    public isPrescription?: boolean
    public dateStartToString?: string
    public dateEndToString?: string
    public history?: ChainingPatientServiceHistory[]
    public datesPrescription?: DateTimeRange
    public datePrescription?: Date

    public constructor(
        public id?: number,
        public refFoodId?: number,
        public patientId?: number,
        public objectClass?: string,
        public objectId?: number,
    ) {}

    public formateItemToFormData(): DietDto {
        return {
            id: this.id,
            ref_food_id: this.refFoodId,
            group_id: this.groupId,
            patient_id: this.patientId,
            object_class: this.objectClass,
            object_id: this.objectId,
            date_start: this.dateStart ? new KhDate(this.dateStart).format('yyyy-MM-dd HH:mm:ss') : undefined,
            date_end: this.dateEnd ? new KhDate(this.dateEnd).format('yyyy-MM-dd HH:mm:ss') : undefined,
            is_important: this.isImportant,
            is_signed: this.isSigned,
            signed_user_id: this.signedUserId,
            is_active: this.isActive,
            is_prescription: this.isPrescription,
        }
    }

    public setDataForView(): void {
        if (this.refFood && this.refFood.id) {
            this.dietRefFoodType = this.refFood.type
            this.dietName = this.refFood.name
        }

        if (this.refSignedUser && this.refSignedUser.id) {
            this.signedUserInitial = this.refSignedUser.shortview
        }
    }

    public openHistory(): void {
        if (this.id) {
            Vue.prototype.$http
                .get(
                    'api/v1/diet/diet/' + this.id + '/history?populate=refLinkedObject&is_active=0,1',
                    new AllergyFoodPatientHistoryApiAdapter(),
                )
                .subscribe(response => {
                    const data = response.getManyData()
                    this.history = data.sort((a, b) => a.date.getTime() - b.date.getTime())
                })
        }
    }
}
