import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import { get } from 'lodash'
import { StayDto } from '../../../dPplanningOp/vue/Type/StayDto'
import Stay from '../../../dPplanningOp/vue/Classes/Stay'

export class AllergyStayAPIAdapter implements ApiDataAdapter<Stay> {
    createClass(apiData: StayDto): Stay {
        return new Stay(
            Number(get(apiData, 'id')),
            get(apiData, 'patient_id'),
            get(apiData, 'praticien_id'),
            get(apiData, 'group_id'),
            get(apiData, 'type'),
            get(apiData, 'entree_preparee'),
            get(apiData, 'entree_modifiee'),
            get(apiData, 'entree_prevue'),
            get(apiData, 'sortie_prevue'),
            get(apiData, 'entree_reelle'),
            get(apiData, 'sortie_reelle'),
            get(apiData, 'entree'),
            get(apiData, 'sortie'),
            get(apiData, 'service_id'),
        )
    }
}
