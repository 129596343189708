import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import { get } from 'lodash'
import { Patient } from '../../../dPpatients/vue/Classes/Patient'
import { PatientDto } from '../../../dPpatients/vue/Type/PatientDto'

export class PatientAPIAdapter implements ApiDataAdapter<Patient> {
    createClass(apiData: PatientDto): Patient {
        return new Patient(
            Number(get(apiData, 'id')),
            get(apiData, 'nom'),
            get(apiData, 'nom_jeune_fille'),
            get(apiData, 'prenom'),
            get(apiData, 'prenom_2'),
            get(apiData, 'prenom_3'),
            get(apiData, 'prenom_4'),
            get(apiData, 'naissance'),
            get(apiData, 'sexe'),
            get(apiData, 'civilite'),
        )
    }
}
