import Stay from '../../../dPplanningOp/vue/Classes/Stay'

export class AllergyLinkSejour {
    constructor(
        public id: number,
        public allergyId: number,
        public sejourId: number,
        public isActive?: boolean,
        public _refLinkedStays?: Stay,
    ) {}
}

