import { AllergyRefReactionAPIAdapter } from '../Adapter/AllergyRefReactionApiAdapter'
import { AllergyRefAllergenAPIAdapter } from '../Adapter/AllergyRefAllergenApiAdapter'
import Vue from 'vue'
import AllergyRefReaction from '../Classes/AllergyRefReaction'
import AllergyRefAllergen from '../Classes/AllergyRefAllergen'
import { AllergyReactionStore } from '../Type/AllergyReactionStore'
import { AllergyAllergenStore } from '../Type/AllergyAllergenStore'
import { AllergyCategoryStore } from '../Type/AllergyCategoryStore'
import { AllergyRefCategory } from '../Classes/AllergyRefCategory'
import { AllergyRefCategoryAPIAdapter } from '../Adapter/AllergyRefCategoryAPIAdapter'
import { tap } from 'rxjs/operators'
import { AllergyFilterStore } from '../Type/AllergyFilterStore'
import { DietAPIAdapter } from '../../../dPprescription/vue/Adapter/DietAPIAdapter'
import Diet from '../../../dPprescription/vue/Classes/Diet'
import { DietRefFoodAPIAdapter } from '../Adapter/DietRefFoodAPIAdapter'
import { DietRefFood } from '../Classes/DietRefFood'
import { DietTypeEnum } from '../Enum/DietTypeEnum'
import { DietRefFoodStore } from '../Type/DietRefFoodStore'
import { DietStore } from '../Type/DietStore'
import AllergyHelper from '../../../soins/vue/classes/AllergyHelper'
import { AllergyEnum } from '../Enum/AllergyEnum'
import { JsonApiResponseAdapter } from '@internal-libraries/kheops-ui-lib'

export default {
    loadReaction(data, args: AllergyReactionStore): Promise<AllergyRefReaction[]> {
        const url = `${AllergyEnum.apiAllergyBase}allergyRefReaction`
        const apiCall = Vue.prototype.$http.get(url, new AllergyRefReactionAPIAdapter())
        apiCall.subscribe((response) => {
            const result = response.getManyData()
            data.commit('saveReactions', {
                reactions: result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    loadAllergies(data, args: AllergyAllergenStore): Promise<AllergyRefAllergen[]> {
        const url = `${AllergyEnum.apiAllergyBase}allergyRefAllergen?populate=refCategory`
        const apiCall = Vue.prototype.$http.get(url, new AllergyRefAllergenAPIAdapter())
        apiCall.subscribe((response) => {
            const result = response.getManyData()
            data.commit('saveAllergies', {
                allergies: result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    filterAllergies(data, args: AllergyFilterStore): Promise<AllergyRefAllergen[]> {
        let parameter = ''
        if (args.search && args.search.length >= 3) {
            parameter = `${parameter}&search=${args.search}`
        }

        if (args.isNonValidated) {
            parameter = `${parameter}&isNonValidated=0`
        }

        const url = `${AllergyEnum.apiAllergyBase}allergyRefAllergen?populate=refCategory${parameter}`
        const apiCall = Vue.prototype.$http.get(url, new AllergyRefAllergenAPIAdapter())
        apiCall.subscribe((response) => {
            const results = response.getManyData()
            const filterResults: AllergyRefAllergen[] = []
            if (args.isNonCoded) {
                results.forEach((allergy: AllergyRefAllergen, key: number) => {
                    if (allergy.refLinkAllergen && allergy.refLinkAllergen.length < 1) {
                        filterResults.push(allergy)
                    }
                })
            }
            data.commit('saveAllergies', {
                allergies: filterResults.length > 0 ? filterResults : results,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    saveAllergyReaction(data, args: AllergyReactionStore): Promise<AllergyRefReaction> {
        const apiCall = Vue.prototype.$http.put(`${AllergyEnum.apiAllergyBase}allergyRefReaction/${args.parameters.id}`, new AllergyRefReactionAPIAdapter(), args.parameters)

        if (args.parameters.id) {
            apiCall.subscribe((response) => {
                const result = response.getOneData()
                data.commit('saveReaction', {
                    reaction: result,
                    storeKey: args.storeKey,
                })
            })
        }

        return apiCall.toPromise()
    },
    isAllergyReactionReferenced({ commit }, args: AllergyReactionStore): Promise<AllergyRefReaction> {
        return Vue.prototype.$http.get(`${AllergyEnum.apiAllergyBase}allergyRefReaction/isReferenced/${args.parameters.id}`, new AllergyRefReactionAPIAdapter()).toPromise()
    },
    deleteAllergyReaction(data, args: AllergyReactionStore): Promise<AllergyRefReaction> {
        const apiCall = Vue.prototype.$http.delete(`${AllergyEnum.apiAllergyBase}allergyRefReaction/${args.parameters.id}`, new AllergyRefReactionAPIAdapter(), args.parameters)
        apiCall.subscribe((response) => {
            const result = response.getOneData()
            data.commit('deleteReaction', {
                reactionId: result.id,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    saveAllergyFood(data, args: AllergyAllergenStore): Promise<AllergyRefAllergen> {
        if (args.parameters.ref_categories) {
            args.parameters._list_link_allergen_category = AllergyRefAllergen.setLinkedCategoriesBeforeStore(args.parameters)
        }

        const apiCall = Vue.prototype.$http.put(
            `${AllergyEnum.apiAllergyBase}allergyRefAllergen/${args.parameters.id}?populate=refCategory`,
            new AllergyRefAllergenAPIAdapter(),
            args.parameters
        )
        apiCall.subscribe((response) => {
            const result = response.getOneData()
            data.commit('saveAllergy', {
                allergy: result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    addAllergyFood({ commit }, args: AllergyAllergenStore): Promise<AllergyRefAllergen> {
        if (args.parameters.ref_categories) {
            const allergyRefAllergen = new AllergyRefAllergen(
                args.parameters.id,
                args.parameters.is_active,
                args.parameters.is_validate,
                args.parameters.group_id,
                args.parameters.name
            )
            args.parameters._list_link_allergen_category = AllergyRefAllergen.setLinkedCategoriesBeforeStore(args.parameters)
        }

        return Vue.prototype.$http
            .post(`${AllergyEnum.apiAllergyBase}allergyRefAllergen?populate=refCategory`, new AllergyRefAllergenAPIAdapter(), args.parameters)
            .pipe(tap((addedAllergy: JsonApiResponseAdapter<AllergyRefAllergen>) => commit('addAllergy', { allergy: addedAllergy.getData(), storeKey: args.storeKey })))
            .toPromise()
    },
    addReaction({ commit }, args: AllergyReactionStore): Promise<AllergyRefReaction> {
        return Vue.prototype.$http
            .post(`${AllergyEnum.apiAllergyBase}allergyRefReaction`, new AllergyRefReactionAPIAdapter(), args.parameters)
            .pipe(tap((addedReaction: JsonApiResponseAdapter<AllergyRefReaction>) => commit('addReaction', { reaction: addedReaction.getData(), storeKey: args.storeKey })))
            .toPromise()
    },
    isAllergyFoodReferenced({ commit }, args: AllergyAllergenStore): Promise<AllergyRefAllergen> {
        return Vue.prototype.$http.get(`${AllergyEnum.apiAllergyBase}allergyRefAllergen/isReferenced/${args.parameters.id}`, new AllergyRefAllergenAPIAdapter()).toPromise()
    },
    deleteAllergyFood(data, args: AllergyAllergenStore): Promise<AllergyRefAllergen> {
        const apiCall = Vue.prototype.$http.delete(`${AllergyEnum.apiAllergyBase}allergyRefAllergen/${args.parameters.id}`, new AllergyRefAllergenAPIAdapter(), args.parameters)
        apiCall.subscribe((response) => {
            const result = response.getOneData()
            data.commit('deleteAllergy', {
                allergyId: result.id,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    loadCategorie(data, args: AllergyCategoryStore): Promise<AllergyRefCategory[]> {
        const url = `${AllergyEnum.apiAllergyBase}allergyRefCategory`
        const apiCall = Vue.prototype.$http.get(url, new AllergyRefCategoryAPIAdapter())
        apiCall.subscribe((response) => {
            const result = response.getManyData()
            data.commit('saveCategories', {
                categories: result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    loadDietPrescription(data, args: DietStore) {
        const url = `${AllergyEnum.apiDietBase}diet?populate=refLinkedObject&is_active=0,1&patient_id=${args.parameters.patient_id}`
        const apiCall = Vue.prototype.$http.get(url, new DietAPIAdapter())
        let fastInProgressPrescription = false
        let fastInProgressUsual = false
        apiCall.subscribe((response) => {
            const results = response.getManyData()
            const dietPrescriptions: Diet[] = []
            const dietUsuals: Diet[] = []
            results.forEach((result) => {
                result.setDataForView()
                result.openHistory()
                if (result?.refFood?.type === DietTypeEnum.fast && result.dateEnd && result.dateEnd.getTime() > new Date().getTime()) {
                    // we load all diet prescription and usual, we can have a fast on prescription and on usual,
                    // we can't use a ternary, because fastInProgressPrescription and fastInProgressUsual can be true
                    if (result.isPrescription) {
                        fastInProgressPrescription = true
                    } else {
                        fastInProgressUsual = true
                    }
                }
                (result.isPrescription ? dietPrescriptions : dietUsuals).push(result)
            })
            // if a fast is in progress, all diets, exept diet with fast type must to display a kh-chip
            if (fastInProgressPrescription) {
                AllergyHelper.setDietInProgress(dietPrescriptions)
            }

            if (fastInProgressUsual) {
                AllergyHelper.setDietInProgress(dietUsuals)
            }
            dietPrescriptions.forEach((dietPrescription: Diet) => {
                dietPrescription.datesPrescription = { start: dietPrescription.dateStart || null, end: dietPrescription.dateEnd || null }
            })
            dietUsuals.forEach((dietUsual: Diet) => {
                dietUsual.datePrescription = dietUsual.dateStart || undefined
            })
            data.commit('saveDietPrescriptions', {
                dietPrescriptions: dietPrescriptions,
                dietUsuals: dietUsuals,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    loadDietRefFood(data, args: DietRefFoodStore): Promise<DietRefFood[]> {
        const type =
            args?.parameters?.type && args.parameters.type !== DietTypeEnum.invalid
                ? `&type=${args.parameters.type}`
                : args?.parameters?.type && args.parameters.type === DietTypeEnum.invalid
                ? '&is_valid=0'
                : ''
        const isPrescriptible = args?.parameters?.is_prescriptible ? '&is_prescriptible=1' : ''
        const isValid = args?.parameters?.all || args.parameters.type === DietTypeEnum.invalid ? '' : '&is_valid=1'
        const url = `${AllergyEnum.apiDietBase}dietRefFood?is_active=0,1${type}${isPrescriptible}${isValid}`
        const apiCall = Vue.prototype.$http.get(url, new DietRefFoodAPIAdapter())
        apiCall.subscribe((response) => {
            const result = response.getManyData()
            data.commit('saveDietRefFood', {
                dietRefFoods: args?.parameters?.returnNodata ? [] : result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    addDietRefFood({ commit }, args: DietRefFoodStore): Promise<DietRefFood> {
        const foodType = args?.parameters?.type ? `&type=${args.parameters.type}` : ''
        const apiCall = Vue.prototype.$http.post(`${AllergyEnum.apiDietBase}dietRefFood?is_prescriptible=1&is_active=0,1${foodType}`, new DietRefFoodAPIAdapter(), args.parameters)
        if (args.parameters && !args.parameters._is_extend) {
            apiCall.pipe(tap((addedDiet: JsonApiResponseAdapter<DietRefFood>) => commit('addDietRefFood', { dietRefFood: addedDiet.getData(), storeKey: args.storeKey })))
        }

        return apiCall.toPromise()
    },
    createDietPrescription({ commit }, args: DietStore): Promise<Diet> {
        return Vue.prototype.$http
            .post(`${AllergyEnum.apiDietBase}diet?populate=refLinkedObject&is_active=0,1`, new DietAPIAdapter(), args.parameters)
            .pipe(
                tap((addedDiet: JsonApiResponseAdapter<Diet>) => {
                    commit('addDietPrescription', { dietPrescription: addedDiet.getData(), storeKey: args.storeKey })
                })
            )
            .toPromise()
    },
    updateDietPrescription(data, args: DietStore): Promise<Diet> {
        const apiCall = Vue.prototype.$http.put(
            `${AllergyEnum.apiDietBase}diet/${args.parameters.id}?populate=refLinkedObject&is_active=0,1`,
            new DietAPIAdapter(),
            args.parameters
        )
        apiCall.subscribe((response) => {
            const result = response.getOneData()
            result.setDataForView()
            data.commit('updateDietPrescription', {
                dietPrescription: result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    checkFastTextureInProgress(data, args: DietStore): Promise<boolean> {
        const apiCall = Vue.prototype.$http.get(`${AllergyEnum.apiDietBase}diet/${args.parameters.sejourId}/checkTextureFast`, new DietAPIAdapter(), args.parameters)
        return apiCall.toPromise()
    },
    deleteDietPrescription(data, args: DietStore): Promise<Diet> {
        const apiCall = Vue.prototype.$http.delete(
            `${AllergyEnum.apiDietBase}diet/${args.parameters.id}?populate=refLinkedObject&is_active=0,1`,
            new DietAPIAdapter(),
            args.parameters
        )
        apiCall.subscribe((response) => {
            const result = response.getOneData()
            data.commit('deleteDietPrescription', {
                dietPrescription: result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    updateDietRefFood(data, args: DietRefFoodStore): Promise<DietRefFood> {
        const apiCall = Vue.prototype.$http.put(
            `${AllergyEnum.apiDietBase}dietRefFood/${args.parameters.id}?is_prescriptible=1&is_active=0,1`,
            new DietRefFoodAPIAdapter(),
            args.parameters
        )
        apiCall.subscribe((response) => {
            const result = response.getOneData()
            data.commit('addDietRefFood', {
                dietRefFood: result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    createDietRefFood({ commit }, args: DietRefFoodStore): Promise<DietRefFood> {
        return Vue.prototype.$http
            .post(`${AllergyEnum.apiDietBase}dietRefFood?is_active=0,1`, new DietRefFoodAPIAdapter(), args.parameters)
            .pipe(
                tap((addedDietRefFood: JsonApiResponseAdapter<DietRefFood>) => {
                    commit('createDietRefFood', { dietRefFood: addedDietRefFood.getData(), storeKey: args.storeKey })
                })
            )
            .toPromise()
    },
    deleteDietRefFood(data, args: DietRefFoodStore): Promise<DietRefFood> {
        const apiCall = Vue.prototype.$http.delete(`${AllergyEnum.apiDietBase}dietRefFood/${args.parameters.id}?is_active=0,1`, new DietRefFoodAPIAdapter(), args.parameters)
        apiCall.subscribe((response) => {
            const result = response.getOneData()
            data.commit('deleteDietRefFood', {
                dietRefFood: result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    isDietReferenced({ commit }, args: DietRefFoodStore): Promise<DietRefFood> {
        return Vue.prototype.$http.get(`${AllergyEnum.apiDietBase}dietRefFood/isReferenced/${args.parameters.id}`, new DietRefFoodAPIAdapter()).toPromise()
    },
    filterDietRefFood(data, args: DietRefFoodStore): Promise<DietRefFood[]> {
        let parameter = '?is_active=0,1'
        if (args.search && args.search.length >= 3) {
            parameter = parameter + '&search=' + args.search
        }

        if (args.isNonValidated && args.isNonValidated === true) {
            parameter = parameter + '&is_valid=0'
        }

        if (args.isNonCoded && args.isNonCoded === true) {
            parameter = parameter + '&isNonCoded=1'
        }

        const url = `${AllergyEnum.apiDietBase}dietRefFood${parameter}`
        const apiCall = Vue.prototype.$http.get(url, new DietRefFoodAPIAdapter())
        apiCall.subscribe((response) => {
            const result = response.getManyData()
            data.commit('saveDietRefFood', {
                dietRefFoods: result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
}
