import { AllergyRefCategory } from './AllergyRefCategory'
import { AllergyLinkAllergenCategory } from './AllergyLinkAllergenCategory'
import { AllergyLinkAllergenCategoryDto } from '../Type/AllergyLinkAllergenCategoryDto'
import { AllergyAllergenDto } from '../Type/AllergyAllergenDto'
import { AllergyCategoryDto } from '../Type/AllergyCategoryDto'

export default class AllergyRefAllergen {
    public constructor(
        public id: number,
        public isActive: boolean,
        public isValidate: boolean,
        public groupId: number,
        public name: string,
        public refCategory?: AllergyRefCategory[],
        public refLinkAllergen?: AllergyLinkAllergenCategory[],
        public refTypeId?: number,
        public tags?: string,
        public isReferenced?: boolean,
        public _search?: string
    ) {}

    public static setLinkedCategoriesBeforeStore(data: AllergyAllergenDto): AllergyLinkAllergenCategoryDto[] {
        data.ref_categories?.forEach((category) => {
            let isRefLinkAllergenToCreate = true
            const categoryCode = 'linkCategoryCode_' + category.id
            const categoryIsActive = 'linkCategoryIsActive_' + category.id
            // we linked allergen for each categories
            if (!data.ref_category) {
                data.ref_category = []
            }
            data.ref_category.push(category)
            if (!data.ref_link_allergen) {
                data.ref_link_allergen = []
            } else {
                data.ref_link_allergen.forEach((allergyLinked: AllergyLinkAllergenCategoryDto, key) => {
                    if (Number(allergyLinked.refCategoryId) === Number(category.id) && data.ref_link_allergen) {
                        allergyLinked.code = data[categoryCode] as string
                        allergyLinked.is_active = (data[categoryIsActive] as boolean) || false
                        isRefLinkAllergenToCreate = false
                    }
                })
            }

            if (isRefLinkAllergenToCreate) {
                const refLinkAllergen: AllergyLinkAllergenCategoryDto = {
                    ref_category_id: category.id as number,
                    code: data[categoryCode] as string,
                    is_active: data[categoryIsActive] as boolean,
                }
                if (data.id) {
                    refLinkAllergen.ref_allergen_id = data.id as number
                }

                data.ref_link_allergen.push(refLinkAllergen)
            }
        })

        return data.ref_link_allergen || []
    }

    public formateItemToFormData(): AllergyAllergenDto {
        const categoriesDto: AllergyCategoryDto[] = []
        this.refCategory?.forEach((category: AllergyRefCategory) => {
            categoriesDto.push(category.formateItemToFormData())
        })

        const linkAllergenDto: AllergyLinkAllergenCategoryDto[] = []
        this.refLinkAllergen?.forEach((linkAllergen: AllergyLinkAllergenCategory) => {
            linkAllergenDto.push(linkAllergen.formateItemToFormData())
        })
        return {
            id: this.id,
            is_active: this.isActive,
            is_validate: this.isValidate,
            group_id: this.groupId,
            name: this.name,
            ref_category: categoriesDto,
            ref_link_allergen: linkAllergenDto,
            ref_type_id: this.refTypeId,
            tags: this.tags,
            isReferenced: this.isReferenced,
            ref_categories: categoriesDto,
        }
    }
}
