import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import { get } from 'lodash'
import Diet from '../Classes/Diet'
import { DietDto } from '../Type/DietDto'
import { DietRefFoodAPIAdapter } from '../../../dPpatients/vue/Adapter/DietRefFoodAPIAdapter'
import { UserAPIAdapter } from '../../../admin/vue/adapters/UserAPIAdapter'
import CommonHelper from '../../../system/vue/Classes/CommonHelper'

export class DietAPIAdapter implements ApiDataAdapter<Diet> {
    public createClass(apiData: DietDto): Diet {
        const refFood = get(apiData, '_refFood')
        const refSignedUser = get(apiData, '_signedUser')
        const isPrescription = get(apiData, 'is_prescription')
        const newDiet = new Diet(
            Number(get(apiData, 'id')),
            get(apiData, 'ref_food_id'),
            get(apiData, 'patient_id'),
            get(apiData, 'object_class'),
            get(apiData, 'object_id'),
        )

        const dateStart = get(apiData, 'date_start', '')
        const dateEnd = get(apiData, 'date_end', '')
        if (dateStart) {
            newDiet.dateStartToString = isPrescription
                ? `${CommonHelper.formatDateToDateString(new Date(dateStart))} ${CommonHelper.formatDateToHourString(
                      new Date(dateStart),
                  )}`
                : `${CommonHelper.formatDateToDateString(new Date(dateStart))}`
        }

        if (dateEnd) {
            newDiet.dateEndToString = `${CommonHelper.formatDateToDateString(
                new Date(dateEnd),
            )} ${CommonHelper.formatDateToHourString(new Date(dateEnd))}`
        }

        newDiet.groupId = get(apiData, 'group_id')
        newDiet.dateStart = dateStart ? new Date(dateStart) : undefined
        newDiet.dateEnd = dateEnd ? new Date(dateEnd) : undefined
        newDiet.isImportant = get(apiData, 'is_important')
        newDiet.isSigned = get(apiData, 'is_signed')
        newDiet.signedUserId = get(apiData, 'signed_user_id')
        newDiet.isActive = get(apiData, 'is_active')
        newDiet.isPrescription = isPrescription
        newDiet.refFood = refFood ? new DietRefFoodAPIAdapter().createClass(refFood) : undefined
        newDiet.refSignedUser = refSignedUser ? new UserAPIAdapter().createClass(refSignedUser) : undefined

        return newDiet
    }
}
