export default class AllergyRefReaction {
    public constructor(
        public id: number,
        public groupId: number,
        public name: string,
        public stage: number,
        public isActive?: boolean,
        public isReferenced?: boolean,
        public _refReaction?: boolean,
        public creationDate?: string,
        public updateDate?: string,
        public labelForMenu?: string,
    ) {}
}
