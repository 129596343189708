/**
 * @author  Kheops Technologies SA <devops@kheops.ch>
 * @copyright Kheops Technologies SA
 */
import Component from 'vue-class-component'
import { Prop, VModel, Watch } from 'vue-property-decorator'
import {
    ClvrPrscPosologyFrequencyCustomizedOptionType,
    ClvrPrscPosologyTextGender,
    ClvrPrscPosologyFrequencyCustomizedValueType,
} from '../types'
import { ClvrPrscPosologyFrequencyGender, ClvrPrscPosologyFrequencyUnit } from '../enum'
import ClvrComponent from '../../../../../../../modules/system/vue/Core/ClvrComponent'
import { KhConfirmModal, KhQuantityPicker, KhQuantityPickerProps, Nullable } from '@internal-libraries/kheops-ui-lib'
import ClvrPrscUnitSelector from '../../../ClvrPrscUnitSelector/ClvrPrscUnitSelector.vue'
import {
    MAX_FREQUENCY_DAY,
    MAX_FREQUENCY_HOUR,
    MAX_FREQUENCY_MINUTE,
    MAX_FREQUENCY_MONTH,
    MAX_FREQUENCY_WEEK,
    MAX_PERIODIC_FREQUENCY_DAY,
    MAX_PERIODIC_FREQUENCY_HOUR,
    MAX_PERIODIC_FREQUENCY_MINUTE,
    MAX_PERIODIC_FREQUENCY_MONTH,
    MAX_PERIODIC_FREQUENCY_WEEK,
} from '../../constants'
import {
    ClvrPrscMaskaPositiveIntegerMask,
    ClvrPrscMaskaPositiveNumberConfig,
    ClvrPrscQuantityPickerDefaultMargin,
} from '../../../../constants'

@Component({
    components: {
        KhConfirmModal,
        KhQuantityPicker,
        ClvrPrscUnitSelector,
    },
})
export default class ClvrPrscPosologyCustomized extends ClvrComponent {
    @VModel({ type: Object, required: true })
    private valuePosology!: ClvrPrscPosologyFrequencyCustomizedValueType
    @Prop({ type: Object, required: true })
    public textPeriod!: ClvrPrscPosologyTextGender
    @Prop({ type: String, default: 'prescriptionClevEHR:prescriptionClevEHR-multiple-by' })
    public textBetween!: string
    @Prop({ type: String, default: '100px' })
    public unitSelectorMaxWidth!: string
    @Prop({ type: String, default: '50px' })
    public quantityPickerMaxWidth!: string
    @Prop({ type: Array, default: () => [] })
    public unitOptions!: ClvrPrscPosologyFrequencyCustomizedOptionType[]
    @Prop({ type: Array, default: () => [] })
    public periodicUnitOptions!: ClvrPrscPosologyFrequencyCustomizedOptionType[]
    @Prop({ type: Boolean, default: false })
    public toSubmit!: boolean

    private unitLabel = ''
    protected readonly UPDATE_POSOLOGY_CUSTOMIZED = 'update-posology-customized'
    private readonly MIN_QUANTITY = 1
    private errorMessages: string[] = []

    public get periodic(): boolean {
        return this.valuePosology?.periodic || false
    }

    public set periodic(value) {
        this.valuePosology.periodic = value

        this.setDataToEmit({
            periodic: value,
            quantity: this.quantity,
            unit: this.unitObject,
            errors: this.errorMessages,
        })
    }
    public get unitObject(): ClvrPrscPosologyFrequencyCustomizedOptionType {
        return this.valuePosology?.unit || this.defaultUnit
    }

    public set unitObject(value) {
        this.valuePosology.unit = value
        this.unitLabel = value?.label || ''
        this.setDataToEmit({
            periodic: this.periodic,
            quantity: this.quantity,
            unit: value,
            errors: this.errorMessages,
        })
    }
    private get quantity(): number {
        return this.valuePosology?.quantity || 1
    }

    private set quantity(value) {
        this.valuePosology.quantity = value
        this.setDataToEmit({
            periodic: this.periodic,
            quantity: value,
            unit: this.unitObject,
            errors: this.errorMessages,
        })
    }

    private get defaultUnit(): ClvrPrscPosologyFrequencyCustomizedOptionType {
        return this.unitOptions.find(option => option.type === ClvrPrscPosologyFrequencyUnit.DAY) ?? this.unitOptions[0]
    }

    private setDataToEmit(newValue: ClvrPrscPosologyFrequencyCustomizedValueType) {
        this.checkError(newValue.quantity, newValue.unit?.type ?? null)
        this.valuePosology = newValue
        newValue.errors = this.errorMessages
        this.emitData(newValue)
    }

    private initValuePosology(): ClvrPrscPosologyFrequencyCustomizedValueType {
        return { periodic: false, quantity: 0, unit: this.defaultUnit, errors: [] }
    }

    public onQuantityChange(quantity: number) {
        this.quantity = quantity
        this.syncSelectedValueWithItems()
    }

    public syncSelectedValueWithItems(): void {
        const foundUnit = this.computedUnitOptions.find(item => item.id === this.valuePosology?.unit?.id)
        if (foundUnit) {
            this.valuePosology?.unit
            this.valuePosology = {
                ...this.valuePosology,
                unit: foundUnit,
            }
        }
    }

    @Watch('valuePosology', { deep: true, immediate: true })
    protected valuePosologyOnChange(): void {
        if (!this.valuePosology) {
            this.valuePosology = this.initValuePosology()
        }
    }

    protected emitData(data: ClvrPrscPosologyFrequencyCustomizedValueType): void {
        if (this.toSubmit) {
            this.$emit(this.UPDATE_POSOLOGY_CUSTOMIZED, data)
        }
    }

    private get computedUnitOptions(): ClvrPrscPosologyFrequencyCustomizedOptionType[] {
        return this.periodic ? this.periodicUnitOptions : this.unitOptions
    }

    /**
     * Retrieve the text displayed in front of the custom selector component
     * Based on the unit gender
     * @protected
     */
    protected get customizedModalTextPeriodBuilt(): string {
        if (!this.unitObject) {
            // default value
            return this.$t(this.textPeriod.masculine)
        }
        return this.$t(
            this.unitLabel && this.unitObject.gender === ClvrPrscPosologyFrequencyGender.FEMININE
                ? this.textPeriod.feminine
                : this.textPeriod.masculine,
        )
    }

    protected get translatedTextBetween(): string {
        return this.$t(this.textBetween)
    }

    /**
     * Remove unwanted selected unit when updating periodic value
     * This ensures that we can not select a value from one list, then switch to the other one and keep a possible wrong
     * selection
     * @private
     */
    @Watch('periodic')
    private onPeriodicChange(): void {
        if (this.unitObject) {
            const indexFound = this.periodic
                ? this.periodicUnitOptions.findIndex(unit => unit.id === this.unitObject.id)
                : this.unitOptions.findIndex(unit => unit.id === this.unitObject.id)
            if (-1 === indexFound) {
                this.unitObject = this.defaultUnit
            }
        }
    }

    private checkError(quantity: number, unitType: Nullable<string>): void {
        this.errorMessages = []
        if (unitType) {
            let quantityMax = 0
            switch (unitType) {
                case ClvrPrscPosologyFrequencyUnit.MINUTE:
                    quantityMax = this.periodic ? MAX_PERIODIC_FREQUENCY_MINUTE : MAX_FREQUENCY_MINUTE
                    break
                case ClvrPrscPosologyFrequencyUnit.HOUR:
                    quantityMax = this.periodic ? MAX_PERIODIC_FREQUENCY_HOUR : MAX_FREQUENCY_HOUR
                    break
                case ClvrPrscPosologyFrequencyUnit.DAY:
                    quantityMax = this.periodic ? MAX_PERIODIC_FREQUENCY_DAY : MAX_FREQUENCY_DAY
                    break
                case ClvrPrscPosologyFrequencyUnit.WEEK:
                    quantityMax = this.periodic ? MAX_PERIODIC_FREQUENCY_WEEK : MAX_FREQUENCY_WEEK
                    break
                case ClvrPrscPosologyFrequencyUnit.MONTH:
                    quantityMax = this.periodic ? MAX_PERIODIC_FREQUENCY_MONTH : MAX_FREQUENCY_MONTH
                    break
            }
            if (quantity > quantityMax) {
                this.errorMessages = [
                    this.$t('prescriptionClevEHR:prescriptionClevEHR-frequency_error_message', {
                        quantityMax: quantityMax,
                    }),
                ]
            }
        }
    }

    private get quantityPickerProps(): KhQuantityPickerProps {
        return {
            inputProps: {
                autosizeMargin: ClvrPrscQuantityPickerDefaultMargin,
                maxWidth: this.quantityPickerMaxWidth,
                mask: ClvrPrscMaskaPositiveIntegerMask,
                maskConfig: ClvrPrscMaskaPositiveNumberConfig,
            },
            min: this.MIN_QUANTITY,
        }
    }
}
