import PatientStoreState from './PatientStoreState'
import PatientStoreGetters from './PatientStoreGetters'
import PatientStoreMutations from './PatientStoreMutations'
import PatientStoreActions from './PatientStoreActions'

export default {
    patientStore: {
        namespaced: false,
        state: PatientStoreState,
        getters: PatientStoreGetters,
        mutations: PatientStoreMutations,
        actions: PatientStoreActions,
    },
}
