import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import { get } from 'lodash'
import { DietRefFood } from '../Classes/DietRefFood'
import { DietRefFoodDto } from '../Type/DietRefFoodDto'
import { SysFieldValue } from '../../../system/vue/common/SysFieldValue'

export class DietRefFoodAPIAdapter implements ApiDataAdapter<DietRefFood> {
    createClass(apiData: DietRefFoodDto): DietRefFood {
        const refCodes = get(apiData, 'refCodes')
        const newDietRefFood =  new DietRefFood(
            get(apiData, 'id'),
            get(apiData, 'type'),
            get(apiData, 'name'),
        )
        const codes: SysFieldValue[] = []
        if (refCodes) {
            refCodes.map(code => {
                if (code.length) {
                    codes.push(new SysFieldValue(
                        code,
                    ))
                }
            })
        }

        newDietRefFood.groupId = get(apiData, 'group_id')
        newDietRefFood.tags = get(apiData, 'tags')
        newDietRefFood.isPrescriptible = get(apiData, 'is_prescriptible')
        newDietRefFood.isUsual = get(apiData, 'is_usual')
        newDietRefFood.isValid = get(apiData, 'is_valid')
        newDietRefFood.isActive = get(apiData, 'is_active')
        newDietRefFood.refCodes = codes
        newDietRefFood.isReferenced = get(apiData, 'isReferenced')

        return newDietRefFood
    }
}
