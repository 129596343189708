import { AllergyRefCategory } from '../Classes/AllergyRefCategory'
import { AllergyCategoryDto } from '../Type/AllergyCategoryDto'
import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import { get } from 'lodash'

export class AllergyRefCategoryAPIAdapter implements ApiDataAdapter<AllergyRefCategory> {
    createClass(apiData: AllergyCategoryDto): AllergyRefCategory {
        return new AllergyRefCategory(
            Number(get(apiData, 'id')),
            get(apiData, 'name'),
            get(apiData, 'group_id'),
            get(apiData, 'is_active'),
        )
    }
}
