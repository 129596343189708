var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "kh-input-select",
    _vm._b(
      {
        staticClass: "clvr-prescription-unit-selector",
        style: {
          "max-width": _vm.maxWidth,
        },
        attrs: {
          "list-class": "clvr-prescription-unit-selector-list",
          "hide-details": "auto",
          "key-field": "id",
          "menu-items": _vm.options,
          disabled: _vm.readonly,
          placeholder: _vm.$t(_vm.placeholder),
          "autosize-margin": _vm.autosizeMargin,
          "init-offset": 36,
          "autosize-on-select": "",
          "is-clickable": "",
          "no-padding": "",
          "disable-unselect": "",
        },
        scopedSlots: _vm._u([
          {
            key: "menuItemContent",
            fn: function ({ item }) {
              return [
                _c("div", { staticClass: "clvr-prescription-modal-text" }, [
                  _vm._v(
                    "\n            " + _vm._s(_vm.$t(item.label)) + "\n        "
                  ),
                ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selectedOptionProxy,
          callback: function ($$v) {
            _vm.selectedOptionProxy = $$v
          },
          expression: "selectedOptionProxy",
        },
      },
      "kh-input-select",
      _vm.inputSelectProps,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }