import AllergyRefReaction from '../Classes/AllergyRefReaction'
import { AllergyLinkReactionForm } from '../Type/AllergyLinkReactionForm'

export class AllergyLinkReaction {
    constructor(
        public refReactionId: number,
        public allergyFoodId?: number,
        public id?: number,
        public isActive?: boolean,
        public _refLinkedReaction?: AllergyRefReaction,
    ) {}

    public formateItemToFormData(): AllergyLinkReactionForm {
        return {
            link_allergy_reaction_id: this.id,
            allergy_food_id: this.allergyFoodId,
            ref_reaction_id: this.refReactionId,
            is_active: this.isActive,
        }
    }
}

