import { AllergyRefCategory } from '../Classes/AllergyRefCategory'
import { AllergyLinkAllergenCategoryDto } from '../Type/AllergyLinkAllergenCategoryDto'

export class AllergyLinkAllergenCategory {
    constructor(
        public id: number,
        public refCategoryId: number,
        public code: string,
        public refAllergenId?: number,
        public isActive?: boolean,
        public _refCategory?: AllergyRefCategory
    ) {}

    public formateItemToFormData(): AllergyLinkAllergenCategoryDto {
        return {
            id: this.id,
            ref_allergen_id: this.refAllergenId,
            ref_category_id: this.refCategoryId,
            code: this.code,
            is_active: this.isActive,
        }
    }
}
