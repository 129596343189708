export const MAX_FREQUENCY_MINUTE = 0
export const MAX_PERIODIC_FREQUENCY_MINUTE = 60

export const MAX_FREQUENCY_HOUR = 12
export const MAX_PERIODIC_FREQUENCY_HOUR = 72

export const MAX_FREQUENCY_DAY = 24
export const MAX_PERIODIC_FREQUENCY_DAY = 365

export const MAX_FREQUENCY_WEEK = 7
export const MAX_PERIODIC_FREQUENCY_WEEK = 53

export const MAX_FREQUENCY_MONTH = 6
export const MAX_PERIODIC_FREQUENCY_MONTH = 0
