export class Patient {
    constructor(
        public id: number,
        public name: string,
        public maidenName: string,
        public firstName: string,
        public firstNameSecond: string,
        public firstNameThird: string,
        public firstNameFourth: string,
        public birthDate: string,
        public sex: string,
        public civility: string,
    ) {}

    public getCivilityKey(): string {
        return 'dPpatients:CPatient.civilite.' + this.civility
    }

    public getPatientFullName(): string {
        return this.name + ' ' + this.firstName
    }
}

