import { Component, VModel, Prop } from 'vue-property-decorator'
import { ClvrPrscUnitOptionType } from './types'
import ClvrComponent from '../../../../../modules/system/vue/Core/ClvrComponent'
import { KhCheckbox, KhInputSelect, KhInputSelectProps, Nullable } from '@internal-libraries/kheops-ui-lib'
import { ClvrPrscDefaultAutosizeMargin } from '../../constants'

@Component({
    components: {
        KhInputSelect,
        KhCheckbox,
    },
})
export default class ClvrPrscUnitSelector<
    T extends ClvrPrscUnitOptionType = ClvrPrscUnitOptionType,
> extends ClvrComponent {
    @VModel({ type: Object, default: null })
    public selectedOption!: Nullable<T>
    @Prop({ type: Array, default: () => [] })
    public options!: T[]
    @Prop({ type: String })
    public maxWidth?: string
    @Prop({ type: Boolean, default: false })
    public readonly!: boolean
    @Prop({ type: String, default: 'prescriptionClevEHR:prescriptionClevEHR-unit' })
    public placeholder!: string
    @Prop({ type: Boolean, default: false })
    public unattached!: boolean

    public autosizeMargin = ClvrPrscDefaultAutosizeMargin

    public get selectedOptionProxy() {
        return this.selectedOption
    }

    public set selectedOptionProxy(newValue: Nullable<T>) {
        this.selectedOption = newValue ?? this.selectedOption
    }

    public get inputSelectProps(): KhInputSelectProps {
        return this.unattached ? {} : { attach: true }
    }
}
