import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import { get } from 'lodash'
import { AllergyLinkReactionDto } from '../Type/AllergyLinkReactionDto'
import { AllergyLinkReaction } from '../Classes/AllergyLinkReaction'
import { AllergyRefReactionAPIAdapter } from '../Adapter/AllergyRefReactionApiAdapter'

export class AllergyLinkReactionAPIAdapter implements ApiDataAdapter<AllergyLinkReaction> {
    createClass(apiData: AllergyLinkReactionDto): AllergyLinkReaction {
        const refLinkedReaction = get(apiData, '_refLinkedReaction')
        return new AllergyLinkReaction(
            get(apiData, 'ref_reaction_id'),
            get(apiData, 'allergy_food_id'),
            Number(get(apiData, 'id')),
            get(apiData, 'is_active'),
            refLinkedReaction ? new AllergyRefReactionAPIAdapter().createClass(refLinkedReaction) : undefined,
        )
    }
}
