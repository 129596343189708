import { Nullable } from '@internal-libraries/kheops-ui-lib'
import { Patient } from '../../../dPpatients/vue/Classes/Patient'

export default class Stay {
    public refPatient: Nullable<Patient> = null

    public constructor(
        public id: number,
        public patientId: number,
        public praticienId?: number,
        public groupId?: number,
        public type?: string,
        public entreePreparee?: string,
        public entreeModifiee?: string,
        public entreePrevue?: string,
        public sortiePrevue?: string,
        public entreeReelle?: string,
        public sortieReelle?: string,
        public entree?: string,
        public sortie?: string,
        public serviceId?: number,
    ) {}
}
